import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import Phaser from "phaser";
import {GRBoot} from "src/gameRoshambo/scenes/GRBoot.js";
import {GRPreloader} from "src/gameRoshambo/scenes/GRPreloader.js";
import {GRGame} from "src/gameRoshambo/scenes/GRGame.js";
import {logDebug} from "src/js/utils/AppLog";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 675,
  height: 1080,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GRBoot,
    GRPreloader,
    GRGame
  ],
};

export const gameRoshamboInfo = () => {
  return {
    'configs': {
      'themeBg': 1,
      'images': [
        {'number': 0, 'url': 'assets/gameRoshambo/adImage1.png'},
        {'number': 1, 'url': 'assets/gameRoshambo/adImage2.png'},
        {'number': 2, 'url': 'assets/gameRoshambo/adImage3.png'},
      ],
      'audios': [{'label': 'Background', 'url': 'assets/gameRoshambo/audios/game_bg.m4a'}],
    },
  };
}

export const GameRoshambo = forwardRef((props, ref) => {
  const {width, height, options} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute, simulate
  }));

  const start = () => {
    game.current.scene.switch('GRGame');
  }

  const restart = (options) => {
    if (game.current) {
      game.current.options.canPlay = options.canPlay;
      if (!game.current.options.simulation) {
        GREventBus.emit('restart');
      }
    }
  }

  const mute = (mute) => {
    if (game.current.options) {
      game.current.options.mute = mute;
    }
    GREventBus.emit('mute', mute);
  }

  const simulate = () => {
    GREventBus.emit('simulate');
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the gameRoshambo inside a useLayoutEffect hook to avoid the gameRoshambo being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      logDebug('DG-game useLayoutEffect');

      if (width && height) {
        config.width = config.height * width/height;
      }
      if (options.simulation) {
        config.type = Phaser.CANVAS;
      }

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;

      if (ref !== null) {
        ref.current = {game: game.current, scene: null};
      }
    }

    return () => {
      if (game.current) {
        logDebug('DG-game destroyed');
        game.current.options = null;
        game.current.destroy(true);
        game.current = undefined;
      }
    }
  }, [ref, width, height, options]);

  useEffect(() => {
    GREventBus.on('eventOnStart', () => {
      if (options && options.onStart) {
        options.onStart();
      }
    });
    GREventBus.on('eventOnEnd', (score) => {
      if (options && options.onEnd) {
        options.onEnd(score);
      }
    });
    window.addEventListener('focus', onAppDidFocus);
    window.addEventListener('blur', onAppDidBlur);

    return () => {
      GREventBus.removeListener('eventOnStart');
      GREventBus.removeListener('eventOnEnd');
      window.removeEventListener('focus', onAppDidFocus);
      window.removeEventListener('blur', onAppDidBlur);
    }
  }, [options])

  return (
    <div id="game-container" style={{backgroundColor: options.bgColor, width: (options.isRotated ? height : width)/options.scale, height: (options.isRotated ? width : height)/options.scale}}/>
  );

});

// Props definitions
GameRoshambo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}

export const GREventBus = new Phaser.Events.EventEmitter();
