import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import {GFKEventBus} from 'src/gameFreeKicks/GFKEventBus';
import Phaser from "phaser";
import {GFKBoot} from "src/gameFreeKicks/scenes/GFKBoot.js";
import {GFKPreloader} from "src/gameFreeKicks/scenes/GFKPreloader.js";
import {GFKMainMenu} from "src/gameFreeKicks/scenes/GFKMainMenu.js";
import {GFKGame} from "src/gameFreeKicks/scenes/GFKGame.js";
import {logDebug} from "src/js/utils/AppLog";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 1080,
  height: 1920,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GFKBoot,
    GFKPreloader,
    GFKMainMenu,
    GFKGame
  ],
};

export const gameInfo = () => {
  return {
    'name': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'imageUrl': 'assets/gameFreeKicks/ball.png',
    'guide': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'themeColor': '#E0863B',
    'resultType': 2,
    'scoreMid': 10,
    'configs': {
      'images': [
        {'number': 0, 'label': 'Ball', 'url': 'assets/gameFreeKicks/ball.png'},
        {'number': 1, 'label': 'Saver1', 'url': 'assets/gameFreeKicks/saver.png'},
        {'number': 2, 'label': 'Saver2', 'url': 'assets/gameFreeKicks/saver_1.png'},
        {'number': 3, 'label': '2xScore', 'url': 'assets/gameFreeKicks/scorer.png'},
        {'number': 4, 'label': 'Ad Banner', 'url': 'assets/gameFreeKicks/adImage.png'}],
      'audios': [{'label': 'Background', 'url': 'assets/gameFreeKicks/audios/game_bg.m4a'}],
    },
  };
}

export const GameFreeKicks = forwardRef((props, ref) => {
  const {width, height, options} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute, simulate
  }));

  const start = () => {
    game.current.scene.switch('GFKGame');
  }

  const restart = (options) => {
    if (game.current) {
      game.current.options.canPlay = options.canPlay;
      if (!game.current.options.simulation) {
        GFKEventBus.emit('restart');
      }
    }
  }

  const mute = (mute) => {
    if (game.current.options) {
      game.current.options.mute = mute;
    }
    GFKEventBus.emit('mute', mute);
  }

  const simulate = () => {
    GFKEventBus.emit('simulate');
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the gameFreeKicks inside a useLayoutEffect hook to avoid the gameFreeKicks being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      if (width && height) {
        config.width = config.height * width/height;
      }
      if (options.simulation) {
        config.type = Phaser.CANVAS;
      }

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;

      if (ref !== null) {
        ref.current = {game: game.current, scene: null};
      }
    }

    return () => {
      if (game.current) {
        logDebug('DG-game destroyed');
        game.current.options = null;
        game.current.destroy(true);
        game.current = undefined;
      }
    }
  }, [ref, width, height, options]);

  useEffect(() => {
    GFKEventBus.on('eventOnStart', () => {
      if (options && options.onStart) {
        options.onStart();
      }
    });
    GFKEventBus.on('eventOnEnd', (score) => {
      if (options && options.onEnd) {
        options.onEnd(score);
      }
    });
    window.addEventListener('focus', onAppDidFocus);
    window.addEventListener('blur', onAppDidBlur);

    return () => {
      GFKEventBus.removeListener('eventOnStart');
      GFKEventBus.removeListener('eventOnEnd');
      window.removeEventListener('focus', onAppDidFocus);
      window.removeEventListener('blur', onAppDidBlur);
    }
  }, [options])

  if (!width || !height) {
    return null;
  }
  return (
    <div id="game-container" style={{backgroundColor: options.bgColor, width: (options.isRotated ? height : width)/options.scale, height: (options.isRotated ? width : height)/options.scale}}/>
  );

});

// Props definitions
GameFreeKicks.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}
